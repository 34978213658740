import { Observer } from "mobx-react-lite";
import React from 'react';
import { REACT_APP_ORIGIN, useGetReactAppOrigin } from "../../env";
import CTAButton from "../CTAButton/CTAButton";
import PageSection from "../PageSection/PageSection";
import './CTASectionTrial.scss';

type CTASectionTrialProps = {
  title? : string,
  // content?: string,
  // buttonLabel?: string,
}

const CTASectionTrial: React.FC<CTASectionTrialProps> = props => {
  const appURL = useGetReactAppOrigin();
  return <Observer children={() => (
    <PageSection
    id="CTASectionTrial"
    >

      <div className="CTASectionTrialInner">
        <article className="CTASectionTrialContent">
          <h2>{props.title ?? 'Ready for a trial?'}</h2>
          <p>Sign up for a 30-day trial and see what trackster can do for you and your team</p>
        </article>
        <CTAButton
          key={appURL}
          title="Get your free trial"
          colorMode="white"
          path={`${appURL ?? REACT_APP_ORIGIN}/auth/register`}
        >
          Get your free trial
        </CTAButton>
        {/* <BaseButton>
        Get your free trial
        </BaseButton> */}
      </div>
    </PageSection>
  )}/>
}

export default CTASectionTrial;