import { Observer } from 'mobx-react-lite';
import React from 'react';
import { TracksterAllBlogPostsQuery } from '../../types/generated';
import joinClassName from '../../utils/className.utils';
import BlogEntry from '../BlogEntry/BlogEntry';
import './BlogEntryList.scss';
 
type AllPosts = TracksterAllBlogPostsQuery["allPosts"];
type FeaturedPost = TracksterAllBlogPostsQuery["featuredPost"];

type BlogEntryListProps = {
  id: string,
  className?: string,
  allMdx: AllPosts | FeaturedPost,
}

const BlogEntryList: React.FC<BlogEntryListProps> = props => {

  return <Observer children={() => (

    <ul className={joinClassName('BlogEntryList', props.className)}>
      {props.allMdx.nodes.map((node: any) => (
        
        <BlogEntry
        key={node.slug}
        id={node.id}
        className=""
        title={node.frontmatter.title}
        featuredImage={node.frontmatter.featured_image}
        date={node.frontmatter.date}
        slug={node.slug}
        excerpt={node.excerpt}
        ></BlogEntry>
      ))}
    </ul>
      
  )} />
}

export default BlogEntryList;