import { graphql, useStaticQuery } from "gatsby";
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BlogEntryList from "../../components/BlogEntryList/BlogEntryList";
import CTASectionTrial from "../../components/CTASectionTrial/CTASectionTrial";
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import PageSection from "../../components/PageSection/PageSection";
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { TracksterAllBlogPostsQuery } from '../../types/generated';
import { ColorPalette } from '../../utils/colors.utils';
import './blog.scss';

const PageBlog: PageComponent = props => {

  // GATSBY NOTES

  // Apparently in regex the middle forward slashes (/) in urls must be escape with \
  // So if necessary depending on structure use /example\/example\/example/"

  // Please note example below for named and typed GraphQL queries as below (or check site homepage as well)
  
  // const AllBlogPosts: TracksterAllBlogPostsQuery = useStaticQuery(graphql`
  // query tracksterAllBlogPosts {

  const AllBlogPosts: TracksterAllBlogPostsQuery = useStaticQuery(graphql`
  query tracksterAllBlogPosts {
    allPosts: allMdx(
      sort: {order: DESC, fields: frontmatter___date}, skip: 1
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      ) {
      nodes {
        frontmatter {
          date(formatString: "D MMMM YYYY")
          title
          featured_image {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
            }
          }
          featured_image_alt
        }
        id
        slug
        body
      }
    }
    featuredPost: allMdx(
      sort: {order: DESC, fields: frontmatter___date},  limit: 1
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      ) {
          nodes {
            frontmatter {
              date(formatString: "D MMMM YYYY")
              title
              featured_image {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
                }
              }
              featured_image_alt
            }
            id
            slug
            body
            excerpt(pruneLength: 140)
          }
        }
  }`
  )

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="PageBlog"
      title="Blog"
      description="Trackster Blog"
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >
      <DefaultPageHeader
        id="BlogPageHeader"
        title="Blog"
        subtitle="Time management news and tips from the trackster team"
        children={
            <BlogEntryList
            id=""
            allMdx={AllBlogPosts.featuredPost}
            ></BlogEntryList>
        }
      ></DefaultPageHeader>

      <PageSection id="BlogEntryListSection">
        <BlogEntryList
        id=""
        allMdx={AllBlogPosts.allPosts}
        ></BlogEntryList>
      </PageSection>

      <CTASectionTrial></CTASectionTrial>

    </PageTemplate>
  )} />
}

export default PageBlog;