import { Observer } from 'mobx-react-lite';
import React from 'react';
import GatsbyImageAdaptor from "../../components/GatsbyImageAdaptor/GatsbyImageAdaptor";
import { ImageWithMetaInfo } from '../../types/app.types';
import { ImageDataLike } from '../../types/gatsby.types';
import joinClassName from '../../utils/className.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import './BlogEntry.scss';

type BlogEntryProps = {
  id?: string, // CSS/HTML #id
  className?: string
  title: string,
  date: string, // Coming from mdx file
  featuredImage?: ImageWithMetaInfo | ImageDataLike,
  featuredImageAlt?: string,
  slug: string,
  excerpt?: string,
}

const BlogEntry: React.FC<BlogEntryProps> = props => {

  return <Observer children={() => (
    <li className={joinClassName('BlogEntry', props.className)} key={props.slug}>
      {/* {console.log(props.featuredImage)} */}
      <AnimatedLink to={`/blog/${props.slug}`} title={props.title}>
        <GatsbyImageAdaptor image={props.featuredImage} />
      </AnimatedLink>
      
      <div className="BlogEntryContent">
        <main className="BlogEntryMain">
          <AnimatedLink to={`/blog/${props.slug}`} title={props.title}>
            <h2>{props.title}</h2>
          </AnimatedLink>
          <div className="BlogEntryMeta">
            <p>{props.date}</p>
          </div>
        </main>
        {props.excerpt ? 
          <aside className="BlogEntryExcerpt">
            <p>{props.excerpt}</p>
          </aside>
          : null
        }
      </div>
      
    </li>
  )} />
}

export default BlogEntry;