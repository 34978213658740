import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ImageWithMetaInfo } from '../../types/app.types';
import { ImageDataLike } from '../../types/gatsby.types';

type GatsbyImageAdaptorProps = {
  image: ImageWithMetaInfo | ImageDataLike,
  id?: string,
  title?: string,
  alt?: string,
  className?: string,
  objectFit?: GatsbyImageProps['objectFit'],
  objectPosition?: GatsbyImageProps['objectPosition'],
  backgroundColor?: string,
}

/**
 * A little adaptor component that automatically applies the getImage method for graphQL image query results.
 * It also accepts title/alt overrides.
 */
const GatsbyImageAdaptor: React.FC<GatsbyImageAdaptorProps> = props => {
  const image = props.image ? getImage(('image' in props.image || 'title' in props.image || 'alt' in props.image) ? props.image?.image : props.image) : null;
  return image ? <GatsbyImage
    id={props.id}
    {...props}
    image={image}
    title={props.title ?? props.image?.title}
    alt={props.alt ?? props.image?.alt ?? ''}
    backgroundColor={props.backgroundColor}
  /> : null;
}

export default GatsbyImageAdaptor;